.cursor-pointer {
  cursor: pointer;
}

.font-sm {
  font-size: 14px;
}

.gradient-primary {
  background-color: red;
  background-image: linear-gradient(
    to right,
    rgba(207, 23, 1, 1),
    rgba(157, 17, 1, 1)
  );
}

#bg-gradient {
  background-color: red;
  height: 5px;
  background-image: linear-gradient(
    to right,
    rgba(207, 23, 1, 1),
    rgba(157, 17, 1, 1)
  );
}

.font-xs {
  font-size: 12px;
}

.fw-semibold {
  font-weight: 600;
}

.rounded-custom {
  border-radius: 12px;
}

.hover-primary:hover {
  color: #cf1701 !important;
}

.border-hover-primary:hover {
  border-color: #cf1701 !important;
}

#bottom-header .dropdown-item-text {
  padding: 0 !important;
}

.dropdown-toggle::after {
  display: none !important;
}

.dropdown-item-header {
  cursor: pointer;
  padding: 0.25rem 1rem;
}

.dropdown-item-header:hover,
.dropdown-item-header:focus {
  color: #1e2125;
  background-color: #e9ecef;
}

.bg-header {
  background-color: #fff !important;
  border-width: 0 !important;
  padding: 0 !important;
}

.bg-grey {
  background-color: #ebebeb;
}

/* .has-line:before,
.has-line:after {
  background-color: rgba(108, 117, 125) !important;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 33%;
  margin: 0 5px;
} */

.disable-copy {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.primary-button-new {
  color: #b33636;
  background: #ffeded;
}

.noheader .btn-close {
  right: 28px;
  position: relative;
  top: 35px;
  padding: 10px !important;
  background-color: white;
  transition: 0.2s;
}

.modal {
  padding-left: 0 !important;
}

.slick-track {
  float: left;
}

.custom-facebook {
  position: relative;
  width: 200px;
}

.custom-facebook:hover {
  background-color: rgba(66, 133, 244, 0.1) !important;
}

.logo-facebook {
  position: absolute;
  left: 10px;
}

.btn.btn-orange {
  background-color: #ea9437;
  color: #fff;
}

.btn.btn-orange:hover {
  background-color: #ea9437a4;
  color: #fff;
}

.grecaptcha-badge {
  visibility: hidden;
}
